@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

body {
  @apply font-sans;
}

.code-block {
  @apply relative bg-gray-900 text-gray-100 p-4 rounded border border-gray-700;
  font-family: 'Roboto', sans-serif; 
}

.line-number {
  @apply text-light-gray absolute left-0 w-10 text-right pr-4;
}

.number {
  color: #FF9A8B; /* Light orange color for numbers */
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.blur-background > *:not(.disabled-message) {
  filter: blur(4px); 
  transition: filter 0.3s ease;
}

.blinking-cursor {
  display: inline-block;
  animation: blink 1s step-end infinite;
}